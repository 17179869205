h1{
  font-size:3em;
  color:#fefefe;
  text-transform:lowercase;
}
/* color  username id red */
#Username{
  color: #fefefe;
  /* font-size: 1.5em; */
  font-weight: bold;
  /* wrap around a square */
  border-radius: 50%; 
  border: 1px solid #fefefe; 
  padding: 0.5em;
  margin-right: 0.5em;
  margin-left: 0.5em;  
}
#comment{
  resize: none;
  width: 100%;
  height: 100%;
}
#comment:focus{
  outline: none;
}
#comment:disabled{
  background-color: #fefefe;
  color: rgb(61, 58, 58);
}
